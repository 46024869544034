import {
  ArrowUp,
  Demo,
  NextOutline,
  NotebookReference,
  User,
} from '@carbon/icons-react';
import {
  Button,
  ChakraProvider,
  Flex,
  Link,
  Show,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import Tippy from '@tippyjs/react';
import CtaButton from './CtaButton';
import MobileMenu from './MobileMenu';
import { ReactComponent as ZinkLogo } from './ZinkLogo.svg';

let ctaIcon = <NextOutline color="var(--teal-300)" size="16" />;
export const PrimaryNavItems = [
  // {
  //   text: 'Product',
  //   name: <ProductMenuItemsMobile />,
  //   id: 'homeProductDropdown',
  //   dropIcon: <ChevronDownIcon />,
  //   leftIcon: <LogicalPartition color="var(--icon-color)" />,
  // },
  // {
  //   text: 'Why Us',
  //   name: <WhyUsMenuItemsMobile />,
  //   id: 'homeUSPDropdown',
  //   dropIcon: <ChevronDownIcon />,
  //   leftIcon: <IntentRequestHeal color="var(--icon-color)" />,
  // },
  // {
  //   text: 'Pricing',
  //   link: '/Pricing',
  //   id: 'homePricingDropdown',
  //   name: 'noTippy',
  //   leftIcon: <Currency color="var(--icon-color)" />,
  // },
  // {
  //   text: 'About Us',
  //   link: '/about-us',
  //   id: 'homeCompanyDropdown',
  //   name: 'noTippy',
  //   leftIcon: <Enterprise color="var(--pink-300)" />,
  // },
  {
    text: 'Docs',
    link: 'https://docs.zinkml.com',
    id: 'homeDocs',
    name: 'noTippy',
    leftIcon: <NotebookReference color="var(--teal-300)" />,
  },
  {
    text: 'Book a Demo',
    link: '/request-demo',
    id: 'homeRequestDemo',
    name: 'noTippy',
    leftIcon: <Demo color="var(--teal-300)" />,
  },
  // {
  //   text: 'Sign In',
  //   link: 'https://app.zinkml.com/login',
  //   id: 'homeSignInDropdown',
  //   name: 'noTippy',
  //   leftIcon: <Login color="var(--sky-400)" />,
  // },
];

function NavWebMenuFull(props) {
  return (
    <ChakraProvider>
      <Flex
        bg="var(--bg-5)"
        width="100vw"
        height="3rem"
        justifyContent="center"
        position="fixed"
        top="0rem"
        overflow="visible"
        zIndex="9997"
        borderBottom="1px solid var(--slate-800)"
      >
        <Flex
          width="100%"
          maxWidth="1280px"
          justify="space-between"
          paddingX="5"
        >
          <Flex alignItems="center" as="a" href="/">
            <ZinkLogo width="28px" height="28px" />
            <Text
              // bgGradient="linear(to-l, #7928CA, #FF0080)"
              // bgClip="text"
              // _hover={{
              //   bgGradient: 'linear(to-l, #FF0080, #7928CA )',zZ
              //   bgClip: 'text',
              // }}
              className="logoText"
              paddingLeft="2"
              fontWeight="600"
              fontSize="24px"
            >
              ZinkML
            </Text>
          </Flex>
          {/* <BetaHeader /> */}
          <Flex justify="end" columnGap="30px" alignItems="stretch">
            {/* <Flex
              _hover={{ color: 'var(--teal-300)' }}
              _focus={{ boxShadow: 'none' }}
              alignSelf="center"
            >
              <CtaButton
                text={'Book a Demo'}
                icon={ctaIcon}
                link="https://calendly.com/praween-zinkml/30min"
                width="154px"
              />
            </Flex> */}
            <Flex
              _hover={{ color: 'var(--teal-300)' }}
              _focus={{ boxShadow: 'none' }}
              alignSelf="center"
            >
              <CtaButton
                text={'Try our App'}
                icon={ctaIcon}
                link="https://app.zinkml.com/signup?zm=1"
                width="154px"
                borderRadius="var(--round-full)"
                target="_self"
                signup="true"
              />
            </Flex>
            {PrimaryNavItems.map(item => {
              if (item.name === 'noTippy') {
                return (
                  <Link
                    color="var(--sky-50)"
                    _hover={{ color: 'var(--teal-300)' }}
                    _focus={{ boxShadow: 'none' }}
                    display="flex"
                    alignItems="center"
                    href={item.link}
                    className="text-xs"
                    letterSpacing="0.01em"
                    gap="1"
                    fontWeight="500"
                  >
                    {item.leftIcon}
                    {item.text}
                    {item.dropIcon}
                  </Link>
                );
              } else
                return (
                  <ChakraProvider>
                    <Tippy
                      content={item.name}
                      interactive={'false'}
                      interactiveBorder={''}
                      theme={'tomato'}
                      placement={'bottom'}
                    >
                      <Link
                        color="var(--slate-100)"
                        _hover={{ color: 'var(--teal-300)' }}
                        _focus={{ boxShadow: 'none' }}
                        display="flex"
                        alignItems="center"
                        href={item.link}
                        className="text-xs"
                        letterSpacing="0.01em"
                        gap="1"
                        fontWeight="500"
                      >
                        {item.leftIcon}
                        {item.text}
                        {item.dropIcon}
                      </Link>
                    </Tippy>
                  </ChakraProvider>
                );
            })}
            <Flex
              id="homeSignInDropDown"
              as="a"
              target="_blank"
              color="var(--sky-50)"
              _hover={{ color: 'var(--teal-300)' }}
              _focus={{ boxShadow: 'none' }}
              display="flex"
              alignItems="center"
              href="https://app.zinkml.com/login"
              className="text-xs"
              letterSpacing="0.01em"
              gap="1"
              fontWeight="500"
            >
              <User color="var(--teal-300)" />
              Sign In
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* <HomeSecondaryNav /> */}
      {props.comp}
      <Tooltip label="Back to top" hasArrow placement="top">
        <Button
          as="a"
          size="sm"
          position="fixed"
          bottom="100px"
          right="20px"
          href="#homeTop"
          bg="gray.900"
          color="var(--slate-100)"
          _hover={{ color: 'var(--teal-300)' }}
          _focus={{ boxShadow: 'none' }}
          _active={{ boxShadow: 'none' }}
        >
          <ArrowUp />
        </Button>
      </Tooltip>
      <Tooltip label="Book a Demo" hasArrow placement="top">
        <Button
          as="a"
          size="sm"
          position="fixed"
          bottom="100px"
          right="65px"
          href="/request-demo"
          bg="gray.900"
          color="var(--slate-100)"
          _hover={{ color: 'var(--teal-300)' }}
          _focus={{ boxShadow: 'none' }}
          _active={{ boxShadow: 'none' }}
        >
          <Demo />
        </Button>
      </Tooltip>
    </ChakraProvider>
  );
}
export default function NavComp(props) {
  return (
    <>
      <Show above="768px">
        <NavWebMenuFull comp={props.comp} />
      </Show>
      <Show below="768px">
        <MobileMenu />
      </Show>
    </>
  );
}

// export default function PrimaryNav() {
//   return <Nav comp={SecondaryNavComps.home} />;
// }
